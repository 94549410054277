






































































import { Vue, Component } from 'vue-property-decorator'
import { FormSchema } from 'vue-form-generator'
import { toastMapper } from '@/store/modules/toast'
import UserAPI from '@/api/requests/user'
import { regMail } from '@/config/default/regex'
import { minLengthPassword } from '@/config/default/user'

const Mappers = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component
export default class PasswordRecovery extends Mappers {
  private userId: number | null = null
  private isDisableSendingEmail = true
  private isDisableChangePassword = true
  private isMailTab = true

  private passwordRecoveryData = {
    newPassword: '',
    samePasswordForConfirm: ''
  }

  private sendEmailData = {
    recoveryEmail: ''
  }

  private code = this.$route.query.activationCode

  private onValidatedSendingEmail(isValid: boolean) {
    this.isDisableSendingEmail = isValid
  }

  private onValidatedChangingPassword(isValid: boolean) {
    this.isDisableChangePassword = isValid
  }

  private sendEmailSchema: FormSchema = {
    fields: [
      {
        type: 'bootstrap',
        inputType: 'email',
        model: 'recoveryEmail',
        required: true,
        styleClasses: 'wm-100',
        label: 'Почта',
        placeholder: 'student-leti@etu.ru',
        hint: 'Введите почту, с которой были зарегистрированы в данной системе',
        validator(value, field, model) {
          if (!regMail.test(model.recoveryEmail)) {
            return ['Проверьте правильность ввода почты']
          }
          return []
        }
      }
    ]
  }

  private changePasswordSchema: FormSchema = {
    fields: [
      {
        type: 'bootstrap',
        inputType: 'password',
        model: 'newPassword',
        required: true,
        styleClasses: 'wm-100',
        label: 'Пароль',
        placeholder: 'Пароль',
        validator(value, field, model) {
          if (model.newPassword.length < minLengthPassword) {
            return [`Пароль содержит меньше ${minLengthPassword} символов`]
          }
          return []
        }
      },
      {
        type: 'bootstrap',
        inputType: 'password',
        required: true,
        model: 'samePasswordForConfirm',
        styleClasses: 'wm-100',
        label: 'Подтверждение пароля',
        placeholder: 'Повторите пароль',
        validator(value, field, model) {
          if (model.samePasswordForConfirm.length < minLengthPassword) {
            return [`Пароль содержит меньше ${minLengthPassword} символов`]
          }
          if (model.samePasswordForConfirm !== model.newPassword) {
            return ['Пароли не совпадают']
          }
          return []
        }
      }
    ]
  }

  private formOptions = {
    validateAfterLoad: true,
    validateAfterChanged: true,
    validateAsync: true
  }

  private async created() {
    if (this.code) {
      try {
        const userData = await UserAPI.checkActivationCode(this.code as string)
        this.userId = userData.data.id
        this.isMailTab = false
      } catch {
        this.$router.replace('')
      }
    }
  }

  private async goToChangePassword() {
    try {
      await UserAPI.checkEmailForChangePassword(
        this.sendEmailData.recoveryEmail
      )
      this.$router.push('/auth')
      this.pushToast({
        title: 'Смена пароля',
        message: `На почту <strong>${this.sendEmailData.recoveryEmail}</strong> отправлено сообщение с ссылкой для смены пароля`,
        time: 5
      })
    } catch {
      this.pushToast({
        error: true,
        title: 'Ошибка',
        message:
          'В системе не зарегистрирован пользователь с данной почтой. Проверьте правильность ввода',
        time: 5
      })
    }
  }

  private returnToPreviousPage() {
    this.$router.push('/auth')
  }

  private async tryToChangePassword() {
    const model = this.passwordRecoveryData
    try {
      if (model.newPassword !== model.samePasswordForConfirm) {
        this.pushToast({
          error: true,
          title: 'Ошибка',
          message: 'Пароли не совпадают',
          time: 5
        })
        return
      }

      await UserAPI.passwordRecovery({
        password: model.newPassword,
        activationCode: this.$route.query.activationCode,
        userId: this.userId
      })

      this.pushToast({
        title: 'Успешно',
        message: 'Пароль успешно изменен!',
        time: 5
      })
      this.$router.push('/auth')
    } catch {
      this.pushToast({
        error: true,
        title: 'Ошибка',
        message: 'Ошибка при изменении пароля',
        time: 5
      })
    }
  }
}
